// Firebase App (the core Firebase SDK) is always required and must be listed first
// import firebase from 'firebase';
import firebase from 'firebase';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCxjPSG3fm8vGHUGlKyD78zmy8msP1SWSs',
  authDomain: 'bgms-aa224.firebaseapp.com',
  projectId: 'bgms-aa224',
  storageBucket: 'bgms-aa224.appspot.com',
  messagingSenderId: '577823717687',
  appId: '1:577823717687:web:a85c19291f075e2b9e42b2',
  measurementId: 'G-VN9F0ENXSS',
};
let firebaseAnalytics, firebaseFireStore;
if (typeof window !== 'undefined' && !firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebaseAnalytics = firebase.analytics();
  firebaseFireStore = firebase.firestore();
}

export const analytics = firebaseAnalytics;
export const db = firebaseFireStore;
