import React from 'react';
import { db } from '../../utils/firebase-init';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NotificationActions from '../../redux/NotificationActions';
import SystemError from '../../utils/exception/SystemError';
import NOTI_CONSTANT from '../../constants/noti.constants';
import Router from 'next/router';

function MyMax(myarr) {
  var al = myarr.length;
  let maximum = myarr[al - 1];
  while (al--) {
    if (myarr[al]?.created_at > maximum?.created_at) {
      maximum = myarr[al];
    }
  }
  return maximum;
}

/**
 * Notification 컴포넌트
 *
 * @component
 *
 */
class NotificationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      init: true,
    };
  }

  componentDidMount() {
    const channelId = this.props.channelId;
    const { type = 'player' } = this.props;

    if (type === 'version' || type === 'notice') {
      this.channelNotificaionDocument = db
        .collection('support')
        .doc(type)
        .collection('data');
      this.connectFirestore();
    }

    if (type === 'player') {
      if (channelId) {
        this.channelNotificaionDocument = db
          .collection('channel')
          .doc('notifications')
          .collection(channelId)
          .doc('data');
        this.connectFirestore();
      } else {
        console.error('channel ID가 없습니다.');
      }
    }
  }

  /**
   * Notification을 확인함
   */
  connectFirestore() {
    this.channelNotificaionDocument.onSnapshot(
      doc => {
        if (this.props.type === 'player') {
          if (doc.exists) {
            if (this.state.init) this.setState({ init: false });
            else {
              let docData = doc.data();
              let data = docData.data;
              this.props.getNoti(data);
            }
          }
        } else {
          if (doc.docs?.length > 0) {
            const max = MyMax(doc.docs.map(doc => doc.data()));
            this.props.getNoti(max);
          }
        }
      },
      error => {
        if (error) {
          console.error(error);
          // Router.push();
        }
      }
    );
  }

  checkNotification(notification) {}

  render() {
    const { isDev } = this.props;
    if (isDev) {
      return <></>;
    } else return null;
  }
}

NotificationComponent.propTypes = {
  // channelId: PropTypes.string.isRequired,
  isDev: PropTypes.bool,
};

NotificationComponent.defaultProps = {
  channelId: null,
  /**개발용일때 노티 보내기 버튼이 노출됨 */
  isDev: false,
};

export default connect(
  state => state,
  (dispatch, ownProps) => {
    return {
      ...NotificationActions(dispatch, ownProps),
    };
  }
)(NotificationComponent);
