import NoticeClass from 'classes/NoticeClass';
import BASIC_CONSTANT from 'constants/basic.constants.js/basic.constants.js';
import React, { useEffect, useState } from 'react';

const Popup = ({ notice }) => {
  const [status, setStatus] = useState(true);
  const [text, setText] = useState();
  const [des, setDes] = useState();

  useEffect(() => {
    const neverOpen = JSON.parse(localStorage.getItem(BASIC_CONSTANT.NOTICE_POPUP));

    textHandle(notice);
    const date = +new Date();
    if (neverOpen?.status) {
      if (notice && neverOpen?.id != notice?.created_at) setStatus(true);
      else setStatus(false);
    } else {
      if (notice?.end_at < date) setStatus(false);
      else setStatus(true);
    }
  }, [notice]);

  const textHandle = notice => {
    if (notice && notice.title) {
      setDes(notice.description.split('\n'));
      setText(notice);
    } else {
      setText(null);
    }
  };

  const close = async (e, type) => {
    let target = e.target.className;
    if (target == 'popup-container-outter') {
      setStatus(false);
    } else {
      if (type) {
        let open = {
          status: true,
          id: notice?.created_at,
        };

        localStorage.setItem(BASIC_CONSTANT.NOTICE_POPUP, JSON.stringify(open));
        setStatus(false);
      }
    }
  };

  return status ? (
    text ? (
      <div className="popup-container-outter" onClick={e => close(e)}>
        <div className="popup-container">
          {/* <h3>업데이트 오류 관련 안내</h3> */}
          <h3>{text && text.title}</h3>
          {/* 을(를) 사용자의 기기에서 사용할 수 있으며 설치할 수 있습니다. */}
          <div className="popup-message">
            {/* <p>
            BGMs 1.0.0 미만 버전의 경우 자동으로 업데이트가 진행되지 않는 업데이트
            <br />
            오류 현상이 발생하고 있습니다.
          </p>
          <p>Download에서 BGMs 1.0.0 파일 재설치 후 이용 부탁드립니다.</p>
          <br />
          <p>이용에 불편을 드려 죄송합니다.</p>
          <br />
          <p>향후 BGMs 1.0.0 버전 이후부터는 자동 업데이트가 정상적으로 진행될 예정이오니 넓은 양해 부탁드립니다.</p>
          <br />
          <p>감사합니다.</p> */}
            {des && des.map(v => <p>{v}</p>)}
            {/* <p>{text && text.description}</p> */}
          </div>
          <div className="btn-wrap">
            <div>
              <button
                id="update-recommend-btn"
                className="btn-remote btn-line update-recommend-btn btn-close"
                onClick={e => close(e, 'never')}>
                다시 보지 않기
              </button>
              {/* <button
          id="update-recommend-btn"
          className="btn-remote btn-line update-recommend-btn"
          onClick={() => dayClose()}>
          하루동안 열지 않기
        </button> */}
              {/* <button id="update-recommend-btn" className="btn-remote btn-line update-recommend-btn" onClick={() => null}>
            Download
          </button> */}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    )
  ) : (
    <></>
  );
};

export default Popup;
