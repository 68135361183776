import { GET_USER_PROFILE, LOGOUT, GET_CHANNEL_USER_PROFILE } from './saga/UserSaga';

const UserActions = (dispatch, ownProps) => {
  return {
    updateUser: token => {
      dispatch({ type: GET_USER_PROFILE, token });
    },
    updateChannelUser: (token, channelId) => {
      dispatch({ type: GET_CHANNEL_USER_PROFILE, token, channelId });
    },
    logout: () => {
      dispatch({ type: LOGOUT });
    },
  };
};

export default UserActions;
