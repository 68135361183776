import React from 'react';
import QuestionMark from '../../assets/img/questionMark.jsx';

class Help extends React.Component {
  openHandle() {
    window.open('https://dodlab.notion.site/BGMs-6a23e8b4ff434153867328c7b4ea52be', '_blank');
  }

  render() {
    return (
      <div className="help-container" onClick={() => this.openHandle()}>
        <div className="tooltip">도움이 필요하세요?</div>
        <div className="tooltip-circle">
          <QuestionMark />
        </div>
      </div>
    );
  }
}

export default Help;
