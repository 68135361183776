const NOTI_CONSTANT = {
  SKIP: 'SKIP',
  CHANGE: 'CHANGE',
  VOLUME_CHANGED: 'VOLUME',
  ADD: 'ADD',
  FULLSCREEN: 'FULLSCREEN',
  DELETE: 'REMOVE',
};

export default NOTI_CONSTANT;
