import BASIC_CONSTANT from 'constants/basic.constants.js/basic.constants.js';
import React from 'react';
import { withTranslation } from 'utils/with-i18next';
import Logo from '../../assets/img/Logo';
import packageInfo from '../../../package.json';

function Footer({ t }) {
  return (
    <footer className="root">
      <div className="container">
        <div className="row">
          <div className="logo-wrap">
            <a id="logo-ff" className="logo" href="/">
              <Logo />
            </a>
          </div>
          <div className="menu">
            <h3>{t('menu')}</h3>
            <ul>
              <li>
                <a id="link-download2" className="underLine" href="/download">
                  {t('link.download')}
                </a>
              </li>
              {/* <li>
                <a id="link-mobile-remote2" className="underLine" href={BASIC_CONSTANT.REMOTE_WEB_URL}>
                  Mobile Remote
                </a>
              </li> */}
              <li>
                <a
                  id="link-guide3"
                  className="underLine"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dodlab.notion.site/BGMs-6a23e8b4ff434153867328c7b4ea52be">
                  {t('link.guide')}
                </a>
              </li>
              <li>
                <a
                  id="link-upadteNote3"
                  className="underLine"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dodlab.notion.site/BGMs-3b0efa9d813c4bafb23a1603df74212f">
                  {t('link.updateNote')}
                </a>
              </li>
            </ul>
          </div>
          <div className="contact">
            <h3>{t('contactUs')}</h3>
            <a id="mailto2" className="btn btn-small" href="mailto:cs@bgms.live?subject=[BGMs] Inquiry email">
              {t('link.emailUs')}
            </a>
          </div>
          <div className="etc">
            <h3>&nbsp;</h3>
            <ul>
              <li>
                <a id="tu" className="underLine" href="/policy/service">
                  {/* 이용약관 */}
                  {t('link.TermsofUse')}
                </a>
              </li>
              <li>
                <a id="pp" className="underLine" href="/policy/privacy">
                  {/* 개인정보 처리방침 */}
                  {t('link.PrivacyPolicy')}
                </a>
              </li>
              <li>
                <p className="underLine">Made by dodlab Inc.</p>
              </li>
              <li>
                <p className="underLine">v {`${packageInfo?.version} (${packageInfo?.lastUpdate})`}</p>
              </li>
            </ul>
          </div>
          <p className="lang">{t('languages.kr')}</p>
        </div>
      </div>
    </footer>
  );
}

export default withTranslation('common')(Footer);
