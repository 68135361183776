import React from 'react';

class QuestionMark extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: !props.width ? 11.1 : props.width,
      height: !props.height ? 16.15 : props.height,
      color: !props.color ? '#ddfc10' : props.color,
    };
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox={`0 0 ${this.state.width} ${this.state.height}`}>
        <path
          id="question-solid"
          d="M31.162,0a6.111,6.111,0,0,0-5.429,2.871A.758.758,0,0,0,25.9,3.908L27.257,4.94a.757.757,0,0,0,1.049-.131c.79-.99,1.376-1.56,2.61-1.56.97,0,2.171.625,2.171,1.565,0,.711-.587,1.077-1.545,1.614-1.117.626-2.6,1.406-2.6,3.356v.309a.757.757,0,0,0,.757.757h2.286a.757.757,0,0,0,.757-.757V9.912C32.746,8.56,36.7,8.5,36.7,4.845,36.7,2.09,33.839,0,31.162,0Zm-.316,11.78a2.185,2.185,0,1,0,2.185,2.185A2.187,2.187,0,0,0,30.846,11.78Z"
          transform="translate(-25.597)"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default QuestionMark;
