import { requestFetch, REQUEST_TYPE } from '../utils/requestFetch';
import BASIC_CONSTANT from '../constants/basic.constants.js/basic.constants.js';
import SystemError from '../utils/exception/SystemError';

export default class NoticeClass {
  constructor(data = {}) {}

  async notice() {
    try {
      const result = await requestFetch(`${BASIC_CONSTANT.BASE_URL}notice`, REQUEST_TYPE.GET, {}, {});
      if (result.code === 200) return result.data;
    } catch (e) {
      return SystemError.processRequestError(e);
    }
  }
}
