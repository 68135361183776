import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Logo from '../../assets/img/Logo';
import { withTranslation } from 'utils/with-i18next';
import { connect } from 'react-redux';
import UserActions from '../../redux/UserActions';
import BASIC_CONSTANT from '../../constants/basic.constants.js/basic.constants.js';
import URL_CONSTANT from '../../constants/url.constants.js';
import Router from 'next/router';
import NextHead from 'next/head';
const ls = require('localStorage');
import SelectLanguages from './SelectLanguages';

// import SelectLanguages from './SelectLanguages';
import { Global, css } from '@emotion/react';
import CustomLink from './CustomLink';

function Header(props) {
  const signitureColor = '#ddfc10';
  const t = props.t;
  const user = props.UserRedux;
  const [isOn, toggleIsOn] = useState(false);
  const [pathData, setPathData] = useState({ url: '/' });
  const [isMenu, setIsMenu] = useState(false);
  const [isPlayer, setIsPlayer] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    const pathName = path.substring(path.indexOf('/') + 1);
    // const pathName = path.split('en/')[1];
    const pathData = pathName ? URL_CONSTANT[pathName] : URL_CONSTANT.home;
    setPathData(pathData);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 650) {
        toggleIsOn(false);
      }
    });
    if (window.location.href.indexOf('player') > -1) {
      setIsPlayer(true);
    } else {
      setIsPlayer(false);
    }
  }, [props.UserRedux.user]);

  const logout = () => {
    localStorage.removeItem(BASIC_CONSTANT.USER_TOKEN_KEY);
    props.logout();
    Router.push(URL_CONSTANT.home.url);
  };

  return (
    <>
      {isOn && (
        <Global
          styles={css`
            body {
              height: 100vh;
              overflow: hidden;
            }
          `}
        />
      )}
      <header>
        <div className="header-inner">
          <a id="logo-tt" className="mina-logo" href="/">
            <Logo />
          </a>
          {user.user && user.user.name ? (
            <>
              <div className="wrapB">
                {pathData && pathData.title && <span className="header-tt">{pathData.title}</span>}
                {pathData.channelDetail && (
                  <span className="header-tt">{user.channelUser && user.channelUser.channelName}</span>
                )}
              </div>
              <button
                style={{
                  backgroundColor: pathData.channelDetail
                    ? user.channelUser && user.channelUser.colorCode
                    : signitureColor,
                }}
                id="userBtn-1"
                className="userBtn"
                onClick={() => setIsMenu(!isMenu)}
                onBlur={() => setTimeout(() => setIsMenu(false), 150)}
                tabIndex={0}>
                {pathData.channelDetail
                  ? user.channelUser && user.channelUser.memberNickname.charAt(0).toUpperCase()
                  : user.user.name.charAt(0).toUpperCase()}
                <ul className={`popup-menu ${isMenu ? 'on' : ''}`}>
                  <li>
                    <CustomLink id="popup-menu-myChannel" href={URL_CONSTANT.myChannel.url} name={t('My Channel')} />
                  </li>
                  {pathData && !pathData.channelDetail && (
                    <li>
                      <CustomLink
                        id="popup-menu-myaccount"
                        href={URL_CONSTANT.myAccount.url}
                        name={t('link.myAccount')}
                      />
                    </li>
                  )}
                  <li onClick={() => logout()}>
                    <CustomLink href={BASIC_CONSTANT.REMOTE_WEB_URL} name="Mobile Remote" />
                  </li>
                  <li>
                    <span id="popup-menu-logout" onClick={() => logout()}>
                      {t('link.logout')}
                    </span>
                  </li>
                </ul>
              </button>
            </>
          ) : (
            <>
              <span className={`link-login ${pathData.url === '/download' ? 'opacity' : ''}`}>
                <CustomLink id={'link-login'} className="underLine" href={'/login'} name={t('link.login')} />
              </span>
              <div id="btn-ham" className={`btn-ham ${isOn ? 'on' : ''}`} onClick={() => toggleIsOn(!isOn)}>
                <div id="btn-ham-bun" className="bun">
                  <span id="btn-ham-burger" className="burger"></span>
                </div>
              </div>
            </>
          )}

          {pathData && !pathData.title && !pathData.channelDetail && (
            <ul className={`menu ${pathData.url === '/login' ? 'opacity' : ''}`}>
              <li>
                <CustomLink id={'link-download'} className="underLine" href={'/download'} name={t('link.download')} />
              </li>
              {/* <li className={pathData.url === '/download' ? 'opacity' : ''}>
                <CustomLink
                  id="link-remote-mobile"
                  className="underLine"
                  href={BASIC_CONSTANT.REMOTE_WEB_URL}
                  name="Mobile Remote"
                />
              </li> */}
              <li className={pathData.url === '/download' ? 'opacity' : ''}>
                <a
                  id="link-guide"
                  className="underLine"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dodlab.notion.site/BGMs-6a23e8b4ff434153867328c7b4ea52be">
                  {t('link.guide')}
                </a>
              </li>
              <li className={pathData.url === '/download' ? 'opacity' : ''}>
                <a
                  id="link-upadteNote"
                  className="underLine"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dodlab.notion.site/BGMs-3b0efa9d813c4bafb23a1603df74212f">
                  {t('link.updateNote')}
                </a>
              </li>
            </ul>
          )}

          <div style={{ display: 'none' }}>
            <SelectLanguages t={t} />
          </div>
        </div>
        <div className={`mobile_nav ${isOn ? 'on' : ''}`}>
          <div className="mobile_nav_inner">
            <ul className="">
              <li>
                <CustomLink id={'link-download1'} className="underLine" href={'/download'} name={t('link.download')} />
              </li>
              <li className={pathData.url === '/download' ? 'opacity' : ''}>
                <a
                  id="link-guide1"
                  className="underLine"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dodlab.notion.site/BGMs-6a23e8b4ff434153867328c7b4ea52be">
                  {t('link.guide')}
                </a>
              </li>
              <li className={pathData.url === '/download' ? 'opacity' : ''}>
                <a
                  id="link-upadteNote1"
                  className="underLine"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dodlab.notion.site/BGMs-3b0efa9d813c4bafb23a1603df74212f">
                  {t('link.updateNote')}
                </a>
              </li>
              <li>
                <CustomLink id={'link-login1'} className="underLine" href={'/login'} name={t('link.login')} />
              </li>
            </ul>
            <div className="contact">
              <h3>{t('contactUs')}</h3>
              <div>
                <a id="mailto-1" className="btn btn-small" href="mailto:cs@bgms.live?subject=[BGMs] Inquiry email">
                  {t('link.emailUs')}
                </a>
                <p className="lang">{t('languages.kr')}</p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

Header.propTypes = {
  t: PropTypes.func,
};

export default connect(
  state => state,
  (dispatch, ownProps) => {
    return { ...UserActions(dispatch, ownProps) };
  }
)(withTranslation('common')(Header));
