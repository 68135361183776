import { ON_NOTIFICATION } from './NotificationRedux';
import { SEND_NOTIFICATION } from './saga/NotificationSaga';

const NotificationActions = (dispatch, ownProps) => {
  return {
    updateNotification: notification => {
      dispatch({
        type: ON_NOTIFICATION,
        notification,
      });
    },
    sendNotification: notification => {
      dispatch({
        type: SEND_NOTIFICATION,
        notification,
      });
    },
  };
};

export default NotificationActions;
