import React from 'react';
const queryString = require('query-string');
const ls = require('localStorage');
import BASIC_CONSTANT from '../constants/basic.constants.js/basic.constants.js';
import URL_CONSTANT from '../constants/url.constants.js';
import Router from 'next/router';

class PageComponent extends React.PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.getParam();
  }

  componentDidUpdate() {
    this.validationPage();
  }

  validationPage() {
    if (this.props.UserRedux && this.props.UserRedux.user) {
      const user = this.props.UserRedux.user;
      const path = window.location.pathname;
      const pathName = path.substring(path.indexOf('/') + 1);

      // console.log(this.props.UserRedux, this.props.UserRedux.user, user, pathName);
      // return;
      if (pathName === 'login') {
        // console.log('로그인 되어있는 경우 마이 채널로 redirect');
        if (user) Router.push(URL_CONSTANT.myChannel.url);
      } else if (pathName && URL_CONSTANT[pathName].loginCheck) {
        // Router.push(URL_CONSTANT.login.url);
        if (!user) Router.push(URL_CONSTANT.login.url);
      } else if (!pathName || !URL_CONSTANT[pathName].loginCheck) {
        // console.log('페이지 노출');
      }
    }
  }

  /**
   * 유저 체크 함수
   */
  checkUser(pathData) {
    //토큰이 있을 경우, 토큰이 없을 경우, redux에 유저 데이터가 있는 경우, 토큰이 있지만 정상 토큰이 아닐 경우 구분해서 처리해줘야함.
    this.token = localStorage.getItem(BASIC_CONSTANT.USER_TOKEN_KEY);

    if (this.token) {
      this.props.updateUser(this.token);
      document.cookie = `${BASIC_CONSTANT.USER_TOKEN_KEY}=${this.token}; domain=bgms.live; path=/`;
    } else if (!this.token && pathData.loginCheck) Router.push(URL_CONSTANT.login.url);

    if (pathData.channelDetail) {
      this.props.updateChannelUser(this.token, this.params.channelId);
    }
  }

  /**
   * page query string 가져오기 this.params에 저장됨
   */
  getParam() {
    const parsed = queryString.parse(location.search);
    this.params = parsed;
    const path = window.location.pathname;
    const pathName = path.substring(path.indexOf('/') + 1);
    // const pathName = path.split('en/')[1];
    const pathData = pathName ? URL_CONSTANT[pathName] : URL_CONSTANT.home;
    this.checkUser(pathData);
  }

  render() {
    return <></>;
  }
}

export default PageComponent;
