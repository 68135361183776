import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Header from 'components/Header';
// import Banner from 'components/Banner';
import Footer from 'components/Footer';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Help from 'components/Help/Help';
import NotificationComponent from 'components/Notification/NotificationComponent';
import Popup from 'components/Popup';

const isServer = typeof window === 'undefined';
const WOW = !isServer ? require('wow.js') : null;

function Layout({ children }) {
  const [isShow, setIsShow] = React.useState(false);
  const [notice, setNotice] = React.useState();
  const [version, setVersion] = React.useState('');

  useEffect(() => {
    new WOW().init();
    const v = localStorage.getItem('version');
    if (v) setVersion(v);
  }, []);

  const handleNotice = data => setNotice(data);

  const getVersion = v => {
    setVersion(v);
    localStorage.setItem('version', JSON.stringify(v));
  };

  return (
    <>
      <ToastContainer transition={Flip} />
      <Header />

      <div className="main">
        {/* <Banner /> */}
        {children}
      </div>
      <NotificationComponent type="notice" getNoti={data => handleNotice(data)} />
      {!version && <NotificationComponent type="version" getNoti={data => getVersion(data)} />}
      <Popup notice={notice} />
      <Help />
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
