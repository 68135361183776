import React from 'react';

class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: !props.width ? 67.542 : props.width,
      height: !props.height ? 18.567 : props.height,
      color: !props.color ? '#fafafa' : props.color,
    };
  }

  render() {
    return (
      <svg
        className={this.props.className}
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox={`0 0 ${this.state.width} ${this.state.height}`}>
        <path
          id="path_909"
          data-name="path 909"
          d="M14.648-17.773l-2-2H.732V-1.221H12.646l2-2V-8.508l-2-1.99,2-2ZM8.386-6.47,6.995-5.09V-8.655H8.386Zm0-6.653L6.995-11.743V-14.5H8.386ZM23.73-7.849,22.351-6.47v-8.044h5.64l2-1.99v-3.271H18.079l-2,2V-3.223l2,2h9.912l2-2v-9.912H23.73ZM44.153-19.775l-2,2-1.99-2H31.384V-1.208h6.274V-11.743l1.379-1.379V-1.208h6.274V-11.743l1.379-1.379V-1.208h6.274V-19.775Zm24.121,8.643-2-1.99h-5.64V-14.5h7.642v-5.273H56.36l-2,2v7.91l2,2h5.652V-6.47H54.358v5.249H66.272l2-2Z"
          transform="translate(-0.732 19.775)"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default Logo;
